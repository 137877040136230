/**
 * DESKTOP_APP conditional flag & Imports
 * @type {boolean}
 */
let DESKTOP_APP = (typeof window != "undefined") ? window.location.host === 'localhost:8950' : true;

/**
 * General Settings.
 */
class Settings {

    static allowSSEByDefault = true;  // Prod: "true";

    static cipheredAuthKey = "AuthorizationCiphered";

    /**
     *  The root authorities server is the One that returns the services map.
     * @return {string|string}
     */
    static getRootAuthoritiesServer() {
        if (DESKTOP_APP || ((typeof window != "undefined") && (window.location.hostname.indexOf("developer.home") !== -1 || window.location.hostname.indexOf("localhost") !== -1))) {
            if (DESKTOP_APP) {
                // Used by Electron only but located here to prevent circular imports.
                // Used only when running electron
                return "http://localhost:8972";
            } else {
                // it is a web player running locally.
                return `${window.location.protocol}//${window.location.host}`;
            }
        }
        return `${window.location.protocol}//${window.location.host}`;
    }

    static maxHistorySize = 1000;

    static excludedExpressions = []; //['EventParser', 'EventInterpreter', 'servicesDidUpdate']; // Prod: ?;

}

/**
 * Log handlers
 */
(function () {

    // Log filter.
    let _shouldBeProceeded = function (message) {
        for (let k in Settings.excludedExpressions) {
            if (message && (typeof message === 'string') && message.includes(Settings.excludedExpressions[k])) {
                return false;
            }
        }
        return true
    };

    let _log = console.log;
    let _error = console.error;
    let _warning = console.warning;

    console.log = function (logMessage) {
        if (_shouldBeProceeded(logMessage)) {
            _log.apply(console, horodate(arguments));
        }
    };

    console.error = function (errMessage) {
        if (_shouldBeProceeded(errMessage)) {
            _error.apply(console, horodate(arguments));
        }
    };

    console.warning = function (warnMessage) {
        if (_shouldBeProceeded(warnMessage)) {
            _warning.apply(console, horodate(arguments));
        }
    };

    const horodate = (args) => {
        let arrayArgs = Array.prototype.slice.call(args);
        arrayArgs.unshift(logDateTime());
        return arrayArgs;
    };

})();


/**
 * Defines the context.
 */
class Context {

    constructor() {
        this.baseUrl = "";
        this.gid = "";
        this.eid = "";
        this.did = "";
        this.mid = "";
        this.token = "";
        this.services = "";
        this.locale = "fr_FR";
    }

    gidDidMid() {
        return `${this.gid}-${this.did}-${this.mid}`
    }
}

/**
 * The player state is sent by player in response to SSE instruction "aps" Ask Player State.
 * It encapsulate all the required data to configure a remote display.
 * It s sent back to the API that relays to all the connected remotes via an "rps" SSE Instruction (Receive Player State).
 */
class PlayerState {
}


/**
 * The base page class.
 */
class Page {
    constructor(context) {

        if (!context) {
            this.context = new Context();
        } else {
            this.context = context;
        }
    }

    syntaxHighlight(json) {
        if (typeof json != 'string') {
            json = JSON.stringify(json, undefined, 2);
        }
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            let cls = 'json-number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'json-key';
                } else {
                    cls = 'json-string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'json-boolean';
            } else if (/null/.test(match)) {
                cls = 'json-null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }
}

/**
 * The fetch wrapper.
 */
class Fetch {

    static createDir(filePath) {
        try {
            fs.mkdirSync(path.dirname(filePath), {recursive: true});
        } catch {
            // Silent
        }
    }

    /**
     *
     * @param url
     * @param token
     * @param method
     * @param allowSSE
     * @param body
     * @param type
     * @param signal
     * @param numTries
     * @returns {Promise<Response>}
     */
    static withAuthentication(url, token, method = 'GET', allowSSE = Settings.allowSSEByDefault, body = null, type = null, signal = null, numTries = 3) {
        let headers = {};
        if (!token) {
            throw `[Fetch.withAuthentication] token is undefined: ${method} ${url}`;
        }
        headers.Authorization = `Bearer ${token}`;
        if (allowSSE === false) {
            headers.Respond = 'true';
            headers.Invoke = 'false';
        }
        if (type) {
            headers['Content-Type'] = type;
        }
        return Fetch.do(url, {
            headers: headers,
            method,
            body,
            signal
        }).then(response => {
            if (response.status >= 400) {
                if (response) {
                    // Clone the response to prevent "Body stream is locked"
                    let clonedResponse = response.clone();
                    clonedResponse.json().then((json) => {
                        console.log(json);
                    }).catch((e) => {
                        console.error(`[Fetch].withAuthentication.then.response.json.catch() ${e.message}`);
                    });
                }
            }
            return response
        }).catch((error) => {
            const defaultEndpoint = Reachability.endpointsToMonitor.find(endpoint => {
                const url = new URL(endpoint.url);
                return url.pathname === Reachability.defaultEndpointPath;
            });
            if (defaultEndpoint) {
                defaultEndpoint.check().then((reachable) => {
                    if (!reachable) {
                        console.error("[Fetch].withAuthentication server not reachable");
                    }
                }).catch((e) => {
                    console.error("[Fetch].withAuthentication Reachability error: ", e);
                });
            }
            throw error;
        });
    }

    /**
     *
     * @param input
     * @param init
     * @returns {Promise<Response>}
     */
    static do(input, init) {
        return fetch(input, init);
    }


}

/**
 * The date and time using YYYY-MM-DD_hh-mm-ss format "2006-01-02_15-04-05"
 * @returns {string}
 */
function dateTimeYMDHMS() {
    const now = new Date();
    const year = now.getFullYear();
    const month = "0" + (now.getMonth() + 1); // The first month index is 0
    const day = "0" + now.getDate();
    const hours = "0" + now.getHours();
    const minutes = "0" + now.getMinutes();
    const seconds = "0" + now.getSeconds();
    const formattedDate = year + '-' + month.substr(-2) + '-' + day.substr(-2);
    const formattedTime = hours.substr(-2) + '-' + minutes.substr(-2) + '-' + seconds.substr(-2);
    return formattedDate + "_" + formattedTime;
}


function logDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = "0" + (now.getMonth() + 1); // The first month index is 0
    const day = "0" + now.getDate();
    const hours = "0" + now.getHours();
    const minutes = "0" + now.getMinutes();
    const seconds = "0" + now.getSeconds();
    const formattedDate = year + '-' + month.substr(-2) + '-' + day.substr(-2);
    const formattedTime = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedDate + " " + formattedTime;
}


function currentHMS() {
    const today = new Date();
    return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
}


/////////////////////////
// Authentication
/////////////////////////

function areCookiesEnabled() {
    // Create cookie
    document.cookie = "cookietest=1; SameSite=Lax";
    let ret = document.cookie.indexOf("cookietest=") != -1;
    // Delete cookie
    document.cookie = "cookietest=1; SameSite=Lax; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
}

function errorWithCode(message, code) {
    let error = new Error(message);
    error.code = code;
    return error
}

function filename(path) {
    return path.substring(path.lastIndexOf('/') + 1)
}

function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


/**
 * Encodes a string to Base64 + UrlEncode
 * @param str
 * @returns {string}
 */
function lBtoa(str) {
    try {
        if (typeof btoa === 'function') {
            return btoa(encodeURIComponent(str));
        } else {
            let buffer;
            if (str instanceof Buffer) {
                buffer = str;
            } else {
                buffer = Buffer.from(encodeURIComponent(str.toString()), 'binary');
            }
            return buffer.toString('base64');
        }
    } catch (e) {
        log.error('[Shared.lBtoa] str ', str, 'error:', e.message)
        return str
    }
}

/**
 * Decodes a string from a Base64 + UrlEncoded string.
 * @param str
 * @returns {string}
 */
function lAtob(str) {
    try {
        if (typeof atob === 'function') {
            return decodeURIComponent(atob(str))
        } else {
            return decodeURIComponent(Buffer.from(str, 'base64').toString('binary'));
        }
    } catch (e) {
        log.error('[Shared.lAtob] str ', str, 'error:', e.message)
        return str
    }
}


function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; SameSite=Lax; path=/";
}

function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; SameSite=Lax; Max-Age=-99999999;';
}

/**
 *
 * @param configuration
 * @param controller
 * @param parentElement
 */
function configureButtons(configuration, controller, parentElement) {
    // Reset the button group.
    parentElement.innerHTML = "";
    let l = controller.lpm;
    const buttonsGroups = configuration['buttons'];
    if (buttonsGroups) {
        // Compute the group orders (if declared)
        let groupNames = [];
        let groupOrder = configuration['buttonsGroupOrder'];
        if (groupOrder) {
            for (let groupName in groupOrder) {
                if (buttonsGroups[groupName]) {
                    let index = groupOrder[groupName];
                    groupNames[index] = groupName;
                }
            }
        }
        // Add possibly missing groups.
        for (let groupName in buttonsGroups) {
            if (groupNames.indexOf(groupName) < 0) {
                log.info("[configureDynamicButton] Adding missing groupName ", groupName);
                groupNames.push(groupName);
            }
        }
        const selectedChannel = configuration['selectedCid']?configuration['selectedCid']:'';
        // Iterate on sorted groups.
        groupNames.forEach((groupName) => {
            let groupNameElement = document.createElement('span');
            groupNameElement.classList.add('d-grid', 'gap-2');
            groupNameElement.innerText = l.localize(groupName);
            parentElement.appendChild(groupNameElement);
            buttonsGroups[groupName].forEach(button => {
                configureOneButton(button, controller, parentElement,selectedChannel);
            });
        })
    } else {
        parentElement.innerHTML = `<span class="highlightedItem">${l.localize("No button. Configuration is void!")}</span>`;
    }
}

/**
 * Enables to configure a dynamic button.
 * @param button {
 *     "title": "Button title",
 *     "subTitle": "Button subtitle",
 *      "htmlID": "button-id",
 *      "cssClass": "btn-outline-secondary",
 *      "triggerPayload": {
 *      "action": "action",
 *        "payload": {
 *          "key": "value"
 *          }
 * }
 * @param controller
 * @param parentElement {HTMLElement}
 * @param selectedChannel {string} the selected channel name ("" if void)
 */
function configureOneButton(button, controller, parentElement, selectedChannel ) {
    if (button && controller && parentElement) {

        let l = controller.lpm;
        let subTitle = !button['subTitle'] ? "" : button['subTitle'];
        let senderId = !button['htmlID'] ? "" : button['htmlID'];
        let cssClass = !button['cssClass'] ? "btn-outline-secondary" : button['cssClass'];
        const label = l.localize(button['label']);
        let payload = "";
        let payloadType = "application/json";
        try {
            let p = button['triggerPayload'];
            if (!button['triggerContentType'] || button['triggerContentType'] === "application/json") {
                payload = JSON.stringify(p);
            } else {
                payload = p;
                payloadType = button['triggerContentType'];
            }
            payload = btoa(payload);
        } catch (e) {
            // Silent catch
        }
        const inputElement = document.createElement("input");

        // Channel actions.
        const isChannelAction =  button['triggerURL'].includes("/fleets/useChannel/");
        if (isChannelAction) {
            if (selectedChannel !== "") {
                // It is a channel action.
                if (senderId === `channel-${selectedChannel}`) {
                    cssClass = "btn-primary";
                } else {
                    cssClass = "btn-outline-secondary";
                }
            }
            inputElement.setAttribute('data-channel-button', 'true');
        }

        inputElement.classList.add('btn');
        inputElement.classList.add(cssClass.trim());
        inputElement.type = "submit";
        inputElement.id = senderId;
        inputElement.name = senderId;
        inputElement.value = label;
        inputElement.onclick = function () {
            this.callAction(button['triggerURL'], button['triggerMethod'], payload, payloadType, subTitle, senderId, label).catch((e) => {
                log.error("Error button action triggering", e)
            });
        }.bind(controller);
        const hiddenInput = document.createElement('input');
        hiddenInput.type = 'hidden';
        hiddenInput.value = subTitle;
        parentElement.appendChild(hiddenInput);
        parentElement.appendChild(inputElement);
    }
}


if (typeof module != "undefined") {
    // Electron main process only.
    module.exports = {lAtob, lBtoa, Settings};
}