const fetchConnect = function (host, email, key) {
    return fetch(`${host}/authorities/connect`, {
        method: 'POST',
        body: `key=${key}&email=${email}`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).catch((error) => {
        const defaultReachableEndpoint = Reachability.endpointsToMonitor.find(e => {
            return e.url === `${controller.context.baseUrl}${Reachability.defaultEndpointPath}`
        });
        defaultReachableEndpoint.check().then().catch();
        throw error;
    });
};

const connect = function () {

    const host = window.location.protocol + "//" + window.location.host;
    const mid = document.getElementById('email').value;
    const key = document.getElementById('key').value;

    document.getElementById(`submit-button`).style.opacity = "0.2";
    document.getElementById(`message`).innerText = "";
    return fetchConnect(host, mid, key).then((response) => {
        if (response.status === 200 || response.status === 201) {
            const params = new URL(window.location).searchParams;
            const redirection = params.get("r");
            if (redirection) {
                // Redirect to the previously targeted url.
                window.location.href = redirection;
            } else {
                // If there is no "r" key in the query string.
                // Use the default user page
                window.location.href = host + '/e/u';
            }
        } else {
            return response.json();
        }
    }).then((text) => {
        document.getElementById(`submit-button`).style.opacity = "1";
        if (text !== undefined) {
            document.getElementById(`message`).innerText = text.message;
        }
    })
};


