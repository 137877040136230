////////////////
// REACHABILITY
////////////////

const REACHABILITY_ATTEMPT_DELAY = 10000; // ten seconds.
const SERVER_IS_NOT_REACHABLE_EVENT = 'SERVER_IS_NOT_REACHABLE_EVENT';
const SERVER_IS_REACHABLE_EVENT = 'SERVER_IS_REACHABLE_EVENT';

class ReachableEndpoint {

    delay = REACHABILITY_ATTEMPT_DELAY;

    electronChannel = "";

    url = "";

    // We consider that the endpoint is reachable by default
    isReachable = true;
    previouslyReachable = undefined;

    paused = false;

    /**
     * @type number
     */
    timeOut = undefined;

    constructor(url) {
        this.url = url;
    }

    destroy() {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    }

    setReachability(reachable) {
        this.previouslyReachable = this.isReachable;
        if (this.isReachable !== reachable) {
            if (this.shouldDispatch()) {
                const payload = {url: this.url};
                if (reachable) {
                    document.dispatchEvent(new CustomEvent(SERVER_IS_REACHABLE_EVENT, payload));
                } else {
                    document.dispatchEvent(new CustomEvent(SERVER_IS_NOT_REACHABLE_EVENT, payload));
                }
            }
            if (DESKTOP_APP && this.shouldEmit()) {
                const {ipcRenderer} = require('electron');
                ipcRenderer.send(this.electronChannel, reachable);
            }
        }
        this.isReachable = reachable;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        if (reachable !== true) {
            if (!this.paused) {
                // Check again
                this.timeOut = setTimeout(() => {
                    this.check(this.url).then(() => {
                    }).catch(() => {
                    })
                }, this.delay);
            }
        }
    }

    shouldDispatch() {
        return true;
    }

    shouldEmit() {
        return false;
    }

    /**
     * @type Promise<boolean>
     **/
    check() {
        return new Promise((resolve) => {
            if (!this.paused) {
                return Fetch.do(this.url).then((response) => {
                    const reachable = response.status < 400;
                    this.setReachability(reachable);
                    resolve(reachable);
                }).catch(() => {
                    resolve(false);
                });
            } else {
                resolve(this.isReachable);
            }
        });
    }
}


// Services Reachability
class Reachability {

    /**
     * @type Array<ReachableEndpoint>
     **/
    static endpointsToMonitor = []

    // Default endpoint in the helper
    static defaultEndpointPath = "/reachable"; // tests the usability of the helper

    /** Initialize the Reachability class with endpoints to monitor
     *
     * @param endpointsToMonitor is the Array of ReachableEndpoints to monitor
     */
    static setup(endpointsToMonitor) {
        // If Reachability is already initialized, reset all existing timeouts
        if (Reachability.endpointsToMonitor) {
            Reachability.endpointsToMonitor.forEach(endpoint => {
                endpoint.destroy();
            });
        }
        // Initialize the endpoints to test
        Reachability.endpointsToMonitor = endpointsToMonitor;
        Reachability.endpointsToMonitor.forEach(endpoint => {
            // Trigger the check
            endpoint.check().then(() => {
            }).catch(() => {
            });
        });
    }
}
